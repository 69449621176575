export default {
    "shopLocked": "Shop locked",
    "notOrder": "Not order",
    "lackOfBudget": "Lack of budget",
    "buyInOtherStore": "Buy in other store",
    "accidentallyClickTheStore": "Accidentally click the store",
    "expensivePrice": "Expensive price",
    "haveAnEmergency": "Have an emergency",
    "dontWantToOrder": "Dont want to order",
    "cancelByEmail": "Cancel by email",
    "cancelByChatPage": "Cancel by chatpage",
    "dt": "Đơn trùng",
    "dtest": "Đơn test",
    "ipkqg": "I1",
    "ipt": "I2",
    "iptktp": "I3",
    "ipblocked": "I4",
    "iptgd": "I5",
    "nameblocked": "N4",
    "phoneblocked": "P4",
    "ipn": "Invalid phone number",
    "inc": "INC",
    "odz": "ODZ",
    "kch": "Không có hàng",
    "others": "Others",
}
